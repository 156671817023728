export default class CookieService {
    constructor(url) {
        this.url = url;
        this.pageCookies = this.getAllCookies() || [];
    }

    /**
     * check if cookie exist and update or set the cookies
     * @param hash
     * @param espStatus
     * @param pushStatus
     * @param campaignId
     * @param overrride
     */
    setCookie(hash, espStatus, pushStatus, campaignId, overrride = false) {
        let regMethod = [];
        if (espStatus) {
            regMethod.push('email');
        }
        if (pushStatus) {
            regMethod.push('push');
        }
        let myCookie = this.pageCookies.filter(
            (cookie) => cookie.campaign_id === campaignId
        );
        if (myCookie.length === 0) {
            let newCookie = {
                campaign_id: campaignId,
                hash: hash,
                reg_method: regMethod,
            };
            this.pageCookies.push(newCookie);
        } else {
            if (!overrride) {
                this.pageCookies = this.pageCookies.map((cookie) => {
                    if (cookie.campaign_id === campaignId) {
                        cookie.reg_method = regMethod;
                    }
                    return cookie;
                });
            } else {
                this.pageCookies = this.pageCookies.map((cookie) => {
                    if (cookie.campaign_id === campaignId) {
                        cookie.reg_method = regMethod;
                        cookie.hash = hash;
                    }
                    return cookie;
                });
            }
        }
        document.cookie =
            'registration=' +
            JSON.stringify(this.pageCookies) +
            ';expires=Fri, 31 Dec 9999 23:59:59 GMT;domain=' +
            this.url +
            ';path=/';
    }

    /**
     * get all page cookies
     * parse it to get the cookie with key = 'registration'
     * @returns {null || Array}
     */
    getAllCookies() {
        const cookies = document.cookie.split(';');
        const parseCookies = [];
        let myCookie = null;
        cookies.forEach((cookie) => {
            parseCookies.push(cookie.split('='));
        });
        parseCookies.forEach((cookie) => {
            if (cookie[0].trim() === 'registration') {
                myCookie = JSON.parse(cookie[1]);
            }
        });
        return myCookie;
    }

    /**
     * Check if cookie exists.
     * @param cookieName
     * @returns {null}
     */
    isCookieExists(cookieName) {
        const cookies = document.cookie.split(';');
        const parseCookies = [];
        let myCookie = null;
        cookies.forEach((cookie) => {
            parseCookies.push(cookie.split('='));
        });
        parseCookies.forEach((cookie) => {
            if (cookie[0].trim() === cookieName) {
                myCookie = JSON.parse(cookie[1]);
            }
        });
        return myCookie;
    }

    setInstallCookie() {
        document.cookie =
            'isInstalled=true;expires=Fri, 31 Dec 9999 23:59:59 GMT;domain=' +
            document.domain +
            ';path=/';
    }

    /**
     * Set subscribe request cookie
     */
    setSubscribeRequestCookie(requestNumber) {
        document.cookie =
            'subscribeRequest=' +
            requestNumber +
            ';expires=Fri, 31 Dec 9999 23:59:59 GMT;domain=' +
            document.domain +
            ';path=/';
    }

    /**
     * extract the cookie with the right campaign_id
     * @param campaign_id
     * @returns {null || Object}
     */
    getCookieByCampaign(campaign_id) {
        let myCookie = null;
        this.pageCookies.forEach((cookie) => {
            if (cookie.campaign_id === campaign_id) {
                myCookie = cookie;
            }
        });

        return myCookie;
    }
}
