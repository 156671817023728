export const getRandomElement = (max, prev) => {
    const newNum = Math.floor(Math.random() * max);

    return newNum === prev ? getRandomElement(max, prev) : newNum;
};

export const getUrlParamsObj = (url, pageType = 'gambling') => {
    const urlParams = new URLSearchParams(url);
    const urlParamsObj = {};
    const paramNames = ['refid1', 'refid2', 'refid3'];

    const getParamValue = (name) => {
        const value = urlParams.get(name) || '0';

        if (value !== '0') {
            localStorage.setItem(name, value);
        } else if (localStorage.getItem(name)) {
            return localStorage.getItem(name);
        }

        return value;
    };

    const rid = urlParams.get('rid');

    if (pageType === 'gambling') {
        urlParamsObj['rid'] = rid || null;
        urlParamsObj['hash'] = urlParams.get('hash') || undefined;
    } else if (pageType === 'game') {
        if (rid) {
            urlParamsObj['rid'] = rid;
            localStorage.setItem('rid', rid);
        } else {
            urlParamsObj['rid'] = localStorage.getItem('rid') || null;
        }
    }

    paramNames.forEach((name) => {
        urlParamsObj[name] = getParamValue(name);
    });

    return urlParamsObj;
};

export const arrayFromNumber = (number) => {
    return Array.from({ length: +number }, (_, i) => i + 1);
};

export const setBodyOverflow = ({ overflow }) => {
    document.documentElement.style.setProperty('--overflow', overflow);
};

export const getSecondsDifference = (startDate, endDate) => {
    return +(Math.abs(endDate - startDate) / 1000).toFixed(2);
};

export function getWindowDimensions() {
    return { width: window.innerWidth, height: window.innerHeight };
}

export const getDeviceOrientation = () => {
    return screen.orientation
        ? screen.orientation.type
        : window.orientation === 0 || window.orientation === 180
          ? 'portrait-primary'
          : 'landscape-primary';
};

export const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
};

export const capitalize = (str) => {
    return str[0].toUpperCase() + str.slice(1);
};

export const isWebView = () => {
    if (typeof window === undefined) {
        return false;
    }

    let navigator = window.navigator;

    const standalone = navigator.standalone;
    const userAgent = navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad|macintosh/.test(userAgent);
    const ios_ipad_webview = ios && !safari;

    return ios
        ? (!standalone && !safari) || ios_ipad_webview
        : userAgent.includes('wv');
};

export const handleCacheControl = (url) => {
    if (url.match(/\.data/) || url.match(/\.bundle/)) {
        return 'must-revalidate';
    }
    if (url.match(/\.mp4/) || url.match(/\.wav/)) {
        return 'immutable';
    }
    return 'no-store';
};

export const getHash = () => {
    const hash = window.location.hash.substring(1);

    return hash.includes('?') ? hash.split('?')[0] : hash;
};

export const updateUrlHash = (value) => {
    const urlHash = window.location.hash.substring(1);

    if (!urlHash) return;

    const hashParts = urlHash.split('?');

    if (hashParts.length === 1) {
        // Check if the hash is not a valid phase value.
        // If it is then set the function parameter to the hash value
        if (!isNaN(+hashParts[0])) window.location.hash = value;
    } else {
        let hash;
        let refParams;
        hashParts.map((part) => {
            if (part.includes('refid')) {
                refParams = part;
            } else {
                hash = !isNaN(+part) ? value : part;
            }
        });

        window.location.hash = `${hash}?${refParams}`;
    }
};

export const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/mobile/i.test(ua)) {
        return 'mobile';
    } else if (
        /tablet/i.test(ua) ||
        (navigator.maxTouchPoints && navigator.maxTouchPoints > 1)
    ) {
        return 'tablet';
    } else {
        return 'desktop';
    }
};

export const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for Android devices
    if (/android/i.test(userAgent)) return true;
    // Check for iOS devices (iPhone/iPod/iPad)
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return true;
    // Check for "mobile" in the user agent
    if (/mobile/i.test(userAgent)) return true;
    // Check for small screen size (common for mobile devices)
    if (window.innerWidth <= 800 && window.innerHeight <= 600) return true;

    return false;
};

export const flattenObject = (obj, parentKey = '', result = {}) => {
    for (let key in obj) {
        if (!obj.hasOwnProperty(key)) continue; // Skip inherited properties

        let propName = parentKey ? parentKey + '-' + key : key;

        // If the property is an object, recurse
        if (
            typeof obj[key] === 'object' &&
            obj[key] !== null &&
            !Array.isArray(obj[key])
        ) {
            flattenObject(obj[key], propName, result);
        } else {
            // Check if the value is falsy and skip it if it is
            if (obj[key]) {
                result[propName] = obj[key];
            }
        }
    }
    return result;
};
