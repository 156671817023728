import React, { useContext, useState } from 'react';
import { useOutsideClick } from 'App/Game/hooks';
import { LanguageContext } from '../../../../providers';
import arrowTop from '../../../../assets/arrow-top.svg';
import arrowDown from '../../../../assets/arrow-down.svg';
import './styles.scss';

const languageMenu = [
    { Language: 'Português', value: 'pt' },
    { Language: 'English', value: 'en' },
    { Language: 'Español', value: 'es' },
    { Language: 'Română', value: 'ro' },
    { Language: 'Български', value: 'bg' },
    { Language: 'Ελληνικά', value: 'gr' },
];

const LanguageSelector = ({ gameGa, ga }) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const { locale, changeLanguage } = useContext(LanguageContext);
    const outsideRef = useOutsideClick(() => setIsDropdownVisible(false));

    const handleChangeLanguage = (value) => {
        gameGa?.sendDesignEvent(`Language:Change:${locale}`);
        ga?.basicGaEvent('language', `change_${locale}`);
        changeLanguage(value);
        setIsDropdownVisible(false);
    };

    return (
        <div className='language-dropdown' ref={outsideRef}>
            <div
                className='language'
                onClick={() => setIsDropdownVisible(!isDropdownVisible)}>
                <div className='language-info'>
                    <span>{locale}</span>
                    <img
                        className='language-arrow'
                        src={isDropdownVisible ? arrowTop : arrowDown}
                        width={16}
                        height={12}
                        alt='arrow'
                    />
                </div>
            </div>

            <div
                className={`languages-box ${isDropdownVisible ? 'visible' : ''}`}>
                <ul className={isDropdownVisible ? 'visible' : ''}>
                    {languageMenu.map(({ Language, value }) => (
                        <li key={value}>
                            <button
                                className='languages-box_button'
                                disabled={locale === value}
                                data-lang={value}
                                onClick={() => handleChangeLanguage(value)}>
                                <span
                                    className={
                                        locale === value ? 'selected' : ''
                                    }>
                                    {Language}
                                </span>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            {isDropdownVisible && (
                <div
                    className='languages-filter'
                    onClick={() => setIsDropdownVisible(false)}
                />
            )}
        </div>
    );
};

export default LanguageSelector;
