import React, { memo } from 'react';
import './styles.scss';

/**
 * simple loader with bubble animation
 * @param backgroundColor - string (required)
 * @param loaderColor - string (required)
 * @param isOverlay - boolean (optional)
 * @param loadingProgression - number (optional)
 */
const BubbleLoader = ({
    backgroundColor,
    loaderColor,
    isOverlay = true,
    loadingProgression = 0,
}) => {
    const loadingPercentage = Math.round(loadingProgression * 100);

    return (
        <div
            className={`loader-container${isOverlay ? ' overlay' : ''}`}
            style={{ backgroundColor: backgroundColor }}>
            {loadingProgression && loadingProgression !== 0 ? (
                <p
                    className={'loading-percentage'}
                    style={{ color: loaderColor }}>
                    {loadingPercentage}%
                </p>
            ) : null}
            <div className={`spinner${isOverlay ? ' overlay' : ''}`}>
                <div
                    className='bubble-1'
                    style={{ backgroundColor: loaderColor }}
                />
                <div
                    className='bubble-2'
                    style={{ backgroundColor: loaderColor }}
                />
            </div>
        </div>
    );
};

export default memo(BubbleLoader);
