import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    AppProvider,
    LanguageProvider,
    PushNotificationProvider,
    PwaProvider,
} from './providers';
import config from './config';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <LanguageProvider>
        <PushNotificationProvider>
            <PwaProvider>
                <AppProvider config={config}>
                    <App />
                </AppProvider>
            </PwaProvider>
        </PushNotificationProvider>
    </LanguageProvider>
);
