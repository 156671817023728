import React, { createContext, useEffect, useState } from 'react';

const getPwaPhaseViews = () => {
    return parseInt(localStorage.getItem('pwaPhaseViews') || 0);
};

const isBrowserSupportedPWA = () => {
    const userAgent = navigator.userAgent;
    const isSamsungBrowser = userAgent.includes('SamsungBrowser');
    const isFirefox =
        userAgent.includes('Firefox') && !userAgent.includes('Seamonkey');
    const isOpera = userAgent.includes('Opera') || userAgent.includes('OPR');
    const isSafari =
        userAgent.includes('Safari') && !userAgent.includes('Chrome');

    return !isSamsungBrowser && !isFirefox && !isOpera && !isSafari;
};

/**
 * Checks if app is installable as pwa
 *
 * return boolean
 */
export const isPwaInstallable = () => {
    return window.BeforeInstallPromptEvent && isBrowserSupportedPWA();
};

const getCanShowPwaPhase = () => {
    const showPwaPhase = localStorage.getItem('showPwaPhase') !== 'false';
    const supportPwa = isPwaInstallable();

    return showPwaPhase && supportPwa;
};

/**
 * Checks if app is installed as PWA
 *
 * return 'Installed' | 'NotInstalled' | 'NotSupported'
 */
const getPWAState = () => {
    if (
        localStorage.getItem('pwaStatus') === 'Installed' ||
        window.matchMedia('(display-mode: standalone)').matches ||
        window.navigator.standalone
    ) {
        return 'Installed';
    } else if (!isPwaInstallable()) {
        return 'NotSupported';
    } else {
        return 'NotInstalled';
    }
};

const PwaContext = createContext(null);

const PwaProvider = ({ children }) => {
    const [showPwaPhase, setShowPwaPhase] = useState(() =>
        getCanShowPwaPhase()
    );
    const [pwaStatus, setPwaStatus] = useState(() => getPWAState());
    const [pwaPhaseViews, setPwaPhaseViews] = useState(() =>
        getPwaPhaseViews()
    );
    const [pwaPhaseTotal, setPwaPhaseTotal] = useState(undefined);
    const [deferredPwaPrompt, setDeferredPwaPrompt] = useState(null);
    const [pwaPromptResult, setPwaPromptResult] = useState('default');

    useEffect(() => {
        if (pwaStatus === 'NotInstalled') {
            addPwaInstallEventListeners();

            return () => {
                window.removeEventListener('beforeinstallprompt');
                window.removeEventListener('appinstalled');
            };
        }
    }, []);

    useEffect(() => {
        if (pwaPhaseTotal !== undefined) {
            const curShowPwaPhase =
                pwaStatus === 'NotInstalled' && pwaPhaseTotal > pwaPhaseViews;

            setShowPwaPhase(curShowPwaPhase);
            localStorage.setItem('showPwaPhase', curShowPwaPhase.toString());
        }
    }, [pwaPhaseTotal, pwaPhaseViews, pwaStatus]);

    const updatePwaPhaseViews = () => {
        setPwaPhaseViews((prevValue) => {
            localStorage.setItem('pwaPhaseViews', `${prevValue + 1}`);

            return prevValue + 1;
        });
    };

    const updatePwaStatus = (value) => {
        setPwaStatus(value);
        localStorage.setItem('pwaStatus', value);
    };

    const addPwaInstallEventListeners = () => {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault(); // Prevent the mini-infobar from appearing on mobile
            setDeferredPwaPrompt(e); // Stash the event so it can be triggered later.
        });
        window.addEventListener('appinstalled', () => {
            setDeferredPwaPrompt(null);
        });
    };

    const showInstallPrompt = async (
        gameGA,
        ga,
        deferredPrompt,
        callSource,
        updateLead
    ) => {
        gameGA?.sendDesignEvent(`PWA:CallInstall:${callSource}`);
        ga?.basicGaEvent('pwa', `call_install_${callSource}`);

        if (!deferredPrompt) {
            gameGA?.sendDesignEvent(`PWA:CallInstallFailed:${callSource}`);
            ga?.basicGaEvent('pwa', `call_install_failed_${callSource}`);

            updatePwaStatus('NotInstalled');
            setPwaPromptResult((prev) =>
                prev === 'default' ? 'dismissed' : prev + 1
            );

            return 'not_installed';
        }

        const { outcome } = await deferredPrompt.prompt(); // trigger the browser install prompt

        if (outcome === 'accepted') {
            gameGA.setCustomDimension2('pwa_installed');
            gameGA?.sendDesignEvent(`PWA:Installed:${callSource}`);
            ga?.basicGaEvent('pwa', `installed_${callSource}`);

            updatePwaStatus('Installed');
            setPwaPromptResult('accepted');
            setDeferredPwaPrompt(null);
            updateLead && updateLead({ pwa_install: 'true' });

            return 'installed';
        }

        if (outcome === 'dismissed') {
            gameGA.setCustomDimension2('pwa_not_installed');
            gameGA?.sendDesignEvent(`PWA:InstallDeclined:${callSource}`);
            ga?.basicGaEvent('pwa', `install_declined_${callSource}`);

            updatePwaStatus('NotInstalled');
            setPwaPromptResult((prev) =>
                prev === 'default' ? 'dismissed' : prev + 1
            );

            return 'not_installed';
        }
    };

    return (
        <PwaContext.Provider
            value={{
                showPwaPhase,
                setPwaPhaseTotal,
                deferredPwaPrompt,
                pwaStatus,
                pwaPromptResult,
                updatePwaPhaseViews,
                showInstallPrompt,
            }}>
            {children}
        </PwaContext.Provider>
    );
};

export { PwaProvider, PwaContext };
