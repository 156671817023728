import React, { useContext, useEffect, useState } from 'react';
import polyglot from '../../../../localization';
import { LanguageContext } from '../../../../providers';
import goalVideoPT from '../../../../assets/portugal.mp4';
import goalVideoBG from '../../../../assets/bulgarian.mp4';
import goalVideoRO from '../../../../assets/romania.mp4';
import goalVideoGR from '../../../../assets/greece.mp4';
import goalVideoEN from '../../../../assets/england.mp4';
import goalVideoES from '../../../../assets/spain.mp4';
import playIcon from '../../../../assets/btn-play-icon.svg';
import iphoneImg from '../../../../assets/whyfun_phone.png';
import LazyImg from 'App/React/_UI/LazyImg';
import './styles.scss';

const currentVideo = {
    pt: goalVideoPT,
    en: goalVideoEN,
    es: goalVideoES,
    ro: goalVideoRO,
    bg: goalVideoBG,
    gr: goalVideoGR,
};

const WhyFun = ({ lang, onBtnClick }) => {
    const [SuperGoal, setSuperGoal] = useState('');
    const { locale } = useContext(LanguageContext);

    const changeCurrentVideo = () => {
        return currentVideo[locale] || '';
    };

    const handleScroll = () => {
        setSuperGoal(
            <video
                autoPlay={true}
                muted={true}
                playsInline
                src={changeCurrentVideo()}
                loop
                width='auto'
                height='100%'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;'
            />
        );
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [locale]);

    return (
        <section className='whyfun'>
            <div className='wrapper'>
                <div className='whyfun_main container'>
                    <div className='whyfun_video'>
                        <div className='whyfun_video__bg' />
                        <div className='whyfun_video__wrapper'>
                            <div className='whyfun_video__phone'>
                                <LazyImg
                                    src={iphoneImg}
                                    offset={70}
                                    width={349}
                                    height={703}
                                    alt={polyglot.t(
                                        `${lang}.whyFun.alt.iphone`
                                    )}
                                />
                            </div>
                            <div className={'animation-wrapper'}>
                                {SuperGoal}
                            </div>
                        </div>
                    </div>
                    <div className='whyfun_items'>
                        <h2 className='whyfun_title'>
                            {polyglot.t(`${lang}.whyFun.title`)}
                        </h2>
                        <div className='whyfun_item'>
                            <h3>
                                {polyglot.t(`${lang}.whyFun.ballControl.title`)}
                            </h3>
                            <p>
                                {polyglot.t(`${lang}.whyFun.ballControl.text`)}
                            </p>
                        </div>
                        <div className='whyfun_item'>
                            <h3>
                                {polyglot.t(`${lang}.whyFun.challenging.title`)}
                            </h3>
                            <p>
                                {polyglot.t(`${lang}.whyFun.challenging.text`)}
                            </p>
                        </div>
                        <div className='whyfun_item'>
                            <h3>
                                {polyglot.t(`${lang}.whyFun.puzzleLike.title`)}
                            </h3>
                            <p>
                                {polyglot.t(`${lang}.whyFun.puzzleLike.text`)}
                            </p>
                        </div>
                        <div className='whyfun_item'>
                            <h3>
                                {polyglot.t(
                                    `${lang}.whyFun.customizable.title`
                                )}
                            </h3>
                            <p>
                                {polyglot.t(`${lang}.whyFun.customizable.text`)}
                            </p>
                        </div>
                        <div className='whyfun_item' id='why_fun_visible'>
                            <h3>
                                {polyglot.t(
                                    `${lang}.whyFun.greatExperience.title`
                                )}
                            </h3>
                            <p>
                                {polyglot.t(
                                    `${lang}.whyFun.greatExperience.text`
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='action wrapper'>
                <div className='container'>
                    <p className='action_title info_block'>
                        {polyglot.t(`${lang}.whyFun.subtitle`)}
                    </p>
                    <button className='main-btn' onClick={() => onBtnClick()}>
                        {polyglot.t(`${lang}.button`)}
                        <img
                            src={playIcon}
                            width={20}
                            height={23}
                            alt={polyglot.t(`${lang}.whyFun.alt.btn_play`)}
                        />
                    </button>
                </div>
            </div>
        </section>
    );
};

export default WhyFun;
