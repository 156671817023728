import React, { lazy, Suspense, useContext, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { AppContext, PushContext, PwaContext } from './providers';
import config from './config';
import { getGameDefaults } from './helpers';
import BubbleLoader from 'App/React/_UI/BubbleLoader';
import Landing from './components/Landing';
import './main.scss';

const GameWrapper = lazy(() => import('./components/GameWrapper'));
const SendAppAnalytics = lazy(() => import('./components/SendAppAnalytics'));

const App = () => {
    const {
        pushStatus,
        pushPhaseViews,
        setPushPromptTotal,
        showPushPhase,
        setShowPushPhase,
    } = useContext(PushContext);
    const { showPwaPhase, setPwaPhaseTotal, pwaStatus } =
        useContext(PwaContext);
    const {
        phase,
        checkPhase,
        urlParams,
        setOffer,
        setGameConfig,
        setAdsConfig,
        sharpstarService,
        updatePhase,
        gameGA,
        ga,
    } = useContext(AppContext);

    useEffect(() => {
        checkPhase(showPushPhase, showPwaPhase);
    }, []);

    useEffect(() => {
        sharpstarService &&
            sharpstarService
                .getOffers(undefined, 0, urlParams.rid)
                .then((response) => {
                    const { id, content } = response.offers[0];

                    setOffer({ id, content });
                    setPushPromptTotal(+content['push-prompt-total']);
                    setPwaPhaseTotal(+content['pwa-prompt-total']);

                    localStorage.setItem(
                        'buildVersion',
                        content['build-version']
                    );
                    localStorage.setItem('offerId', id);
                })
                .catch((e) => {
                    const defaults = getGameDefaults(config);

                    setGameConfig(defaults.gameConfig);
                    setAdsConfig(defaults.ad);
                    setShowPushPhase(false);
                    setPwaPhaseTotal(0);
                    Sentry.captureException(e);
                    // todo: fix setShowPushPhase(false) as install has to load after getOffers works again
                });
    }, [sharpstarService]);

    return (
        <>
            {phase === 'landing' ? (
                <Landing
                    updatePhase={updatePhase}
                    showPwaPhase={showPwaPhase}
                    showPushPhase={showPushPhase}
                    gameGA={gameGA}
                    ga={ga}
                />
            ) : (
                <Suspense
                    fallback={
                        <BubbleLoader
                            loaderColor={'#09328d'}
                            backgroundColor={'#b7d6fe'}
                        />
                    }>
                    {!!gameGA && <GameWrapper />}
                </Suspense>
            )}
            {!!gameGA && !!ga && (
                <Suspense fallback={<></>}>
                    <SendAppAnalytics
                        ga={ga}
                        gameGA={gameGA}
                        pushPhaseViews={pushPhaseViews}
                        pwaStatus={pwaStatus}
                        pushStatus={pushStatus}
                    />
                </Suspense>
            )}
        </>
    );
};

export default App;
