import React from 'react';
import polyglot from '../../../../localization';
import logo from '../../../../assets/description_logo.png';
import exciting from '../../../../assets/description_exciting-gameplay.png';
import lose from '../../../../assets/description_lose-game.png';
import LazyImg from 'App/React/_UI/LazyImg';
import './styles.scss';

const GameDescription = ({ lang }) => {
    return (
        <section>
            <div className='footer_top-text wrapper'>
                <div className='container'>
                    <p>{polyglot.t(`${lang}.footer.topText`)}</p>
                </div>
            </div>
            <div className='game_description_content wrapper'>
                <div className='game_description_logo'>
                    <LazyImg
                        url={logo}
                        width={339}
                        height={304}
                        alt={polyglot.t(
                            `${lang}.footer.gameDescription.alt.logo`
                        )}
                    />
                </div>
                <div>
                    <h2 className='game_description_title'>
                        {polyglot.t(
                            `${lang}.footer.gameDescription.playBacanaGolTitle`
                        )}
                    </h2>
                    <ul className='game_description_list'>
                        <li>
                            {polyglot.t(
                                `${lang}.footer.gameDescription.enjoyPossibilityToWin`
                            )}
                        </li>
                        <li>
                            {polyglot.t(
                                `${lang}.footer.gameDescription.exploreFootballAndOnlineGaming`
                            )}
                        </li>
                        <li>
                            {polyglot.t(
                                `${lang}.footer.gameDescription.takeBreakFromRoutine`
                            )}
                        </li>
                        <li>
                            {polyglot.t(
                                `${lang}.footer.gameDescription.marvelAtStunningVisuals`
                            )}
                        </li>
                    </ul>
                </div>
            </div>
            <div className='game_details_wrapper'>
                <div className='game_details_content'>
                    <div className='game_details'>
                        <h2 className='game_description_title'>
                            {polyglot.t(
                                `${lang}.footer.gameDescription.playBacanaGolSubtitle`
                            )}
                        </h2>
                        <p>
                            {polyglot.t(
                                `${lang}.footer.gameDescription.perfectEntertainmentMoment`
                            )}
                        </p>
                        <br />
                        <p>
                            {polyglot.t(
                                `${lang}.footer.gameDescription.accessibleDynamicGameplay`
                            )}
                        </p>
                        <br />
                        <p>
                            {polyglot.t(
                                `${lang}.footer.gameDescription.playAnywhereAnytime`
                            )}
                        </p>
                    </div>
                    <div className='game_details_logo'>
                        <LazyImg
                            url={exciting}
                            width={640}
                            height={495}
                            alt={polyglot.t(
                                `${lang}.footer.gameDescription.alt.when_playing`
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className='game_location_info_content'>
                <div className='game_location_info_logo'>
                    <LazyImg
                        url={lose}
                        width={500}
                        height={460}
                        alt={polyglot.t(
                            `${lang}.footer.gameDescription.alt.where_playing`
                        )}
                    />
                </div>
                <div className='game_location_info_text'>
                    <h2
                        className='game_description_title'
                        id='game_description_visible'>
                        {polyglot.t(
                            `${lang}.footer.gameDescription.whereToPlayBacanaGol`
                        )}{' '}
                    </h2>
                    <p>
                        {polyglot.t(
                            `${lang}.footer.gameDescription.optimizedForMobile`
                        )}
                    </p>
                    <br />
                    <p>
                        {polyglot.t(
                            `${lang}.footer.gameDescription.bacanaGolCompanion`
                        )}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default GameDescription;
