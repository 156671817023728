export const getGameDefaults = (config) => {
    const { buildVersion, buildPath, adProvider, lang, domain } = config;

    return {
        ad: { provider: adProvider },
        gameConfig: {
            buildVersion: buildVersion,
            buildUrl: `${buildPath}${buildVersion}/`,
            main: {
                Language: lang,
                AddressablesSettingsPath: `${domain}build/${buildVersion}`,
                AddressablesBundlePath: `${domain}bundle/${buildVersion}/WebGL`,
            },
            dimensions: {
                values1: ['push_default', 'push_granted', 'push_denied'],
                values2: [
                    'pwa_installed',
                    'pwa_not_installed',
                    'pwa_not_supported',
                ],
                values3: [
                    'Low_RAM',
                    'Medium_Low_RAM',
                    'Medium_RAM',
                    'High_RAM',
                    'Very_High_RAM',
                    'Ultra_High_RAM',
                    'Unknown_RAM',
                ],
            },
        },
    };
};
