import { useCallback, useEffect } from 'react';

const useObserveVisibility = (configurations, gameGA, ga) => {
    const handleIntersection = useCallback(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const targetElement = entry.target;
                    const config = configurations.find(
                        (c) => targetElement.id === c.id
                    );
                    if (config) {
                        const { gameGaEvent, gaEvent } = config;
                        if (gameGaEvent && gameGA) {
                            gameGA.sendDesignEvent(gameGaEvent);
                        }
                        if (gaEvent && ga) {
                            ga.gaEvent(gaEvent);
                        }
                        observer.unobserve(targetElement);
                    }
                }
            });
        },
        [configurations, gameGA, ga]
    );

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection);

        configurations.forEach(({ id }) => {
            const element = document.getElementById(id);
            if (element) {
                observer.observe(element);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [configurations, handleIntersection]);
};

export default useObserveVisibility;
