import React, { createContext, useEffect, useState } from 'react';
import config, { languages } from '../config';

const getPathLocale = () => {
    const pathSegments = window.location.pathname.split('/').filter(Boolean);

    if (pathSegments.length > 0) {
        const lastSegment = pathSegments[pathSegments.length - 1];

        if (languages.includes(lastSegment)) {
            pathSegments.pop();

            return {
                pathName: pathSegments.length
                    ? `/${pathSegments.join('/')}`
                    : '',
                locale: lastSegment,
            };
        } else {
            return {
                pathName: `/${pathSegments.join('/')}`,
                locale: config.lang,
            };
        }
    }

    return { pathName: '', locale: config.lang };
};

const LanguageContext = createContext(null);

const LanguageProvider = ({ children }) => {
    const [path, setPath] = useState(() => getPathLocale());

    useEffect(() => {
        localStorage.setItem(
            'localization',
            path.locale || config.lang.toLowerCase()
        );
    }, [path.locale]);

    const changeLanguage = (language, redirect = true) => {
        const urlLocale = language === config.lang ? '' : `/${language}`;
        const urlSearch = window.location.search ? window.location.search : '';
        const newUrl = `${window.location.origin}${path.pathName}${urlLocale}${urlSearch}${window.location.hash || ''}`;

        if (redirect) {
            window.location.href = newUrl;
        } else {
            setPath((prevPath) => ({
                ...prevPath,
                locale: language,
            }));

            window.history.pushState({}, '', newUrl);
        }
    };

    window.changeLanguage = () => {
        setPath((prev) => ({
            ...prev,
            locale: localStorage.getItem('localization'),
        }));
    };

    return (
        <LanguageContext.Provider
            value={{
                locale: path.locale,
                path: path.pathName,
                changeLanguage,
            }}>
            {children}
        </LanguageContext.Provider>
    );
};

export { LanguageContext, LanguageProvider };
