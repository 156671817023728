import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import polyglot from 'App/Helpers/languages';

const getSlug = (type, lang) => {
    switch (type) {
        case 'about':
            return polyglot.t(lang + '.modals_slug.about_us');
        case 'privacy':
            return polyglot.t(lang + '.modals_slug.privacy_policy');
        case 'terms':
            return polyglot.t(lang + '.modals_slug.terms');
        case 'cookies':
            return 'cookie-policy';
        default:
            Sentry.captureException(
                TypeError(`Slug of type ${type} does not exist`)
            );
            return '';
    }
};

const fetchLocalFile = async (href, type) => {
    try {
        const res = await fetch(href);

        if (res.ok) {
            const data = await res.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(data, 'text/html');

            return {
                title: doc.title,
                content: doc.body.innerHTML,
            };
        } else {
            Sentry.captureException(
                TypeError(
                    `Failed to fetch local file for Terms&Use modal, type - ${type}`
                )
            );
        }
    } catch (error) {
        Sentry.captureException(error);
    }
};

const fetchRemoteFile = async (reqUrl, type) => {
    try {
        const res = await fetch(reqUrl);
        if (res.ok) {
            const data = await res.json();
            return {
                title: data[0].title.rendered,
                content: data[0].content.rendered,
            };
        } else {
            Sentry.captureException(
                TypeError(
                    `Failed to fetch remote file for Terms&Use modal, type - ${type}`
                )
            );
        }
    } catch (error) {
        Sentry.captureException(error);
        return null;
    }
};

const fetchText = async (type, element, url, lang, fetchLocal) => {
    let result;

    if (fetchLocal) {
        // Attempt to fetch the local file first
        result = await fetchLocalFile(element.target.href, type);
        if (result) return result;
    }

    // If local file fetch fails or this step is skipped, attempt to fetch the remote file
    const slug = getSlug(type, lang);
    const reqUrl = url + slug;

    result = await fetchRemoteFile(reqUrl, type);
    if (result) return result;

    // If both attempts fail, return a default modal error content
    return {
        title: 'Something went wrong',
        content: 'No data available at the moment. Please try again later.',
    };
};

/**
 * Hook handles term&use and privacy policy modals
 *
 * @param url - string (required) - fetch url path
 * @param lang - string (required) - app language
 * @param fetchLocal - boolean (optional) - if true, the hook attempts to fetch the local modal content file first, and then the external one
 */
const useTermsAndPrivacyContent = (url, lang, fetchLocal = false) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        content: '',
    });

    useEffect(() => {
        setModalContent({ title: '', content: '' });
    }, [lang]);

    const showTermsUse = async (element, type) => {
        element.preventDefault();
        setIsModalOpen(true);

        try {
            const text = await fetchText(type, element, url, lang, fetchLocal);
            setModalContent(text);
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    const hideTermsUse = () => {
        setIsModalOpen(false);
        setModalContent({ title: '', content: '' });
    };

    return {
        showTermsUse,
        hideTermsUse,
        modalContent,
        isModalOpen,
        fetchLocal,
    };
};

export default useTermsAndPrivacyContent;
